import React, { useCallback, useMemo } from "react";
import { Card, Spinner, Table } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useThunkDispatch } from "store";
import { clientsSelectors, clientsThunks } from "store/clients";
import { RequestStatus } from "types/common.types";
import { ClientModel, ClientRestoreJobModel, JobStatus } from "types/clients.types";
import { contractSelectors } from "store/contracts";
import {
  setClientRestoreJobsCurrentPageAC,
  setClientRestoreJobsPerPageAC,
  setClientRestoreJobsSortFieldAC,
} from "store/clients/clients.actions";
import PaginationBox from "containers/PaginationBox";
import CardFallback from "containers/CardFallback";
import { date30DaysAgoAtNoon, dateTodayAtNoon, formatDateAndTime, formatHoursAndMinutes } from "utils/time";
import { convertGigabytes } from "utils/helpers";

import styles from "./styles.module.css";

export interface ClientBackupJobsTableProps {
  client?: ClientModel;
}

const jobStatusColor = {
  [JobStatus.Success]: styles.successBadge,
  [JobStatus.Warning]: styles.warningBadge,
  [JobStatus.Failed]: styles.failedBadge,
  [JobStatus.Killed]: styles.killedBadge,
  [JobStatus.NoRun]: styles.killedBadge,
};

const ClientRestoreJobsTable = ({ client }: ClientBackupJobsTableProps) => {
  const { t } = useTranslation("clientDetailsPage");
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { sortField, sortOrder, perPage, currentPage, requestStatus } = useSelector(
    clientsSelectors.getClientRestoreJobs
  );
  const { list, pageCount } = useSelector(clientsSelectors.getClientRestoreJobsTable);

  const columnsConfig = useMemo(
    () => [
      {
        key: "jobStatus",
        dataIndex: "jobStatus",
        title: t("clientRestoreJobsTable.jobStatus"),
        render: (jobStatus: ClientRestoreJobModel["jobStatus"]) => (
          <span className={cn(styles.jobStatusBadge, { [jobStatusColor[jobStatus as JobStatus]]: jobStatus })}>
            {jobStatus}
          </span>
        ),
      },
      { key: "application", dataIndex: "application", title: t("clientRestoreJobsTable.application") },
      { key: "instance", dataIndex: "instance", title: t("clientRestoreJobsTable.instance") },
      { key: "user", dataIndex: "user", title: t("clientRestoreJobsTable.user") },
      {
        key: "restoreSize",
        dataIndex: "restoreSize",
        title: t("clientRestoreJobsTable.restoreSize"),
        render: (restoreSize: ClientRestoreJobModel["restoreSize"]) => convertGigabytes(restoreSize),
      },
      {
        key: "startTime",
        dataIndex: "startTime",
        title: t("clientRestoreJobsTable.startTime"),
        render: (time: ClientRestoreJobModel["startTime"]) => formatDateAndTime(time),
      },
      {
        key: "endTime",
        dataIndex: "endTime",
        title: t("clientRestoreJobsTable.endTime"),
        render: (time: ClientRestoreJobModel["endTime"]) => formatDateAndTime(time),
      },
      {
        key: "elapsedTime",
        dataIndex: "elapsedTime",
        title: t("clientRestoreJobsTable.elapsedTime"),
        render: (elapsedTime: ClientRestoreJobModel["elapsedTime"]) => formatHoursAndMinutes(elapsedTime),
      },
    ],
    [t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setClientRestoreJobsSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setClientRestoreJobsPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setClientRestoreJobsCurrentPageAC(value)), [dispatch]);

  const thunkDispatch = useThunkDispatch();
  const reloadData = useCallback(() => {
    thunkDispatch(
      clientsThunks.getClientRestoreJobsInfo(selectedContractId, client?.clientName || "", {
        startDate: date30DaysAgoAtNoon,
        endDate: dateTodayAtNoon,
      })
    );
  }, [client?.clientName, selectedContractId, thunkDispatch]);

  return (
    <Card title={t("clientRestoreJobsTableTitle")} className={styles.card} data-testid="clientRestoreJobsPageContent">
      <div className={styles.cardContent}>
        {(requestStatus === RequestStatus.PENDING || requestStatus === RequestStatus.UNCALLED) && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <>
            <div className={styles.tableBlock}>
              <Table
                dataSource={list}
                columns={columnsConfig}
                sortField={sortField}
                sortOrder={sortOrder}
                onSortFieldChange={handleSortFieldChange}
              />
            </div>
            <PaginationBox
              perPage={perPage}
              currentPage={currentPage}
              pageCount={pageCount}
              onPerPageChange={handlePerPageChange}
              onChangePage={handlePageChange}
            />
          </>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default ClientRestoreJobsTable;
