export interface ClientModel {
  clientId: unknown;
  clientName: string;
  application: string;
  currentSize: unknown;
  labels: unknown;
}

export interface ClientListResponse {
  clients: ClientModel[];
}

export interface LabelModel {
  _id: number;
  name: string;
}

export interface LabelListResponse {
  labels: LabelModel[];
}

export interface AddNewLabelResponse {
  _id: number;
}

export interface JobsRequestConfig {
  startDate: Date;
  endDate: Date;
}

export interface ClientUsageModel {
  date: string;
  size: number;
}

export interface ClientUsageResponse {
  usage: ClientUsageModel[];
}

export interface JobsStatusModel {
  successCount: number;
  successRate: number;
  warningCount: number;
  warningRate: number;
  failedCount: number;
  failedRate: number;
  killedCount: number;
  killedRate: number;
}

export enum JobStatus {
  Success = "Success",
  Warning = "Warning",
  Failed = "Failed",
  Killed = "Killed",
  NoRun = "No Run",
}

export interface ClientBackupJobModel {
  jobStatus: JobStatus | "_";
  application: string;
  level: string;
  backupSize: string;
  startTime: string;
  endTime: string;
  elapsedTime: string;
}

export interface ClientBackupJobsResponse {
  statuses: JobsStatusModel;
  backupJobs: ClientBackupJobModel[];
}

export interface ClientRestoreJobModel {
  jobStatus: JobStatus | "_";
  application: string;
  instance: string;
  user: string;
  restoreSize: string;
  startTime: string;
  endTime: string;
  elapsedTime: string;
}

export interface ClientRestoreJobsResponse {
  restoreJobs: ClientRestoreJobModel[];
}
